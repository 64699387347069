import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BASE_URL_API, IMG_URL } from '../environment';
import { Form, Row, Col } from 'react-bootstrap'
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator';
import Swal from 'sweetalert2'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import noLogo from '../img/logo01.svg';
import validation from 'validator';
export const Agents = () => {

  const [add, setAdd] = useState(false);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);

  // Fetch Agents
  const [agent, setAgent] = useState([]);
  const [status, setStatus] = useState("");
  const [city, setCity] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState("");

  const [name, setName] = useState('');
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [image, setImage] = useState('');
  const [aadharimg,setAadharimg]=useState('');
  const [aadharno,setAadharno]=useState(''); 
  const [insurance,setInsurance]=useState('');
  const [registration,setRegistration]=useState('');
  const [mobile, setMobile] = useState("");
  const [cityId, setCityId] = useState('');
  const [vehicle,setVehicle]=useState('');
  const [isValidMobile, setIsValidMobile] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidAadhar, setIsValidAadhar]=useState(true);
  const [updateImage,setUpdateImage]=useState('');
  const [updateAadharImg,setUpdateAadharImg]=useState('');
  const [updateInsurance,setUpdateInsurance]=useState('');
  const [updateRegistration,setUpdateRegistration]=useState('');
  //fetching agent data and showing in table
  const fetchData = () => {
    axios.get(BASE_URL_API + 'users?utype=agent')
      .then(res => {
        setAgent(res.data);
        if (selectedCityId === "") {
          setAgent(res.data);
        } else {
          const filteredData = res.data.filter(item => item.cityid === selectedCityId);
          console.log(filteredData)
          setAgent(filteredData);
        }
      })
      .catch(err => console.log(err));
    
    axios
    .get(BASE_URL_API + "items/allCityPercent")
    .then((res) => setCity(res.data))
    .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, [selectedCityId]); 
  

  //View Agent
  const [AgeView, setAgeView] = useState([]);
    //const [AgeView, setAgeView] = useState("");


  const viewBtn=(idd)=>{
    handleApiView (idd);
    setView(true);
  }
  const handleApiView = async(id) => {
      await axios.get(BASE_URL_API + 'users/getAgent/'+id)
      .then((res) => {
        console.log(res);
        setAgeView(res.data)
        console.log(AgeView);
      }).catch(err => console.log(err));
  };

  //Create Agents
 

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    const selectedCity = city.cities.find((city) => city._id === selectedCityId);
    if (selectedCity) {
      setCityId(selectedCityId);
    }
  };
  //function for adding agent
  const handleApiAdd = () => {
    if (!name || !email  || !image || !mobile || !cityId || !aadharno ||!vehicle) {
      swalWithBootstrapButtons.fire({
        title: "Error",
        text: "All fields are required!",
        icon: "error",
      });
        if(!validation.isEmail(email)){
          swalWithBootstrapButtons.fire({
            title: "Error",
            text: "Please enter a valid mail",
            icon: "error",
          });
        }
      return;
    }
    const addFormData = new FormData();
          addFormData.append('image', image);
          addFormData.append('name', name);
          addFormData.append('email', email);
          addFormData.append('mobile', mobile);
          addFormData.append('aadhar_no', aadharno);
          addFormData.append('aadhar_imgURL', aadharimg);
          addFormData.append('vehicle_insuranceURL', insurance);
          addFormData.append('vehicle_registrationURL', registration);
          addFormData.append('cityid', cityId);
          addFormData.append("vehicle_type",vehicle)
    console.log(addFormData);
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to add this agent!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
         
  
          axios
          .post(BASE_URL_API + 'users/createbyadmin', addFormData)
            .then((res) => {
              console.log("the response is",res.data.message)
              fetchData();
              if(res.data.message=="mobile number exists." ){
                swalWithBootstrapButtons.fire({
                  title: "Error",
                  text:"the mobile number already exist",
                  icon:'error'
                })
              }
             

              if(res.data.message=="mail exist"){
                swalWithBootstrapButtons.fire({
                  title: "Error",
                  text:"the Email already exist",
                  icon:'error'
                })
              }
           
              if(res.data.message=="saved successfully"){
              swalWithBootstrapButtons.fire(
                "Added!",
                "A New agent added successfully.",
                "success"
              );
            }
  
              setAdd(false);
              setName('');
              setEmail('');
              setMobile('');
              setImage('');
              setPassword('');
              setCityId('');
            }).catch((err) => {
              swalWithBootstrapButtons.fire({
                title: "Error",
                text: "An Error occur!! Try again",
                icon: "error",
              });
              console.log(err)});
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not add this agent",
            "error"
          );
          setEdit(false);
          setName('');
          setEmail('');
          setMobile('');
          setImage('');
          setPassword('');
          setCityId('');
        }
      });
  };
  
  
  

  //Edit Agents

  const [upId, setUpId] = useState();

  const editBtn = (idd) => {
    axios
      .get(BASE_URL_API + 'users/getAgent/' + idd)
      .then((res) => {
        const item = res.data[0];
        setName(item.name);
        setEmail(item.email);
        setMobile(item.mobile);
        setImage(item.imageURL); 
        setUpId(item._id);
        setEdit(true);
        setCityId(item.cityid);
        setInsurance(item.vehicle_insuranceURL);
        setRegistration(item.vehicle_registrationURL);
        setAadharimg(item.aadhar_imgURL);
        setAadharno(item.aadhar_no || "");

      })
      .catch((err) => console.log(err));
  };
  

  // Delete agent function
  const apiDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
    axios.delete(BASE_URL_API + 'users/delete/' + id)
      .then(res => {
        Swal.fire(
          'Deleted!',
          'This Agent has been deleted.',
          'success'
        )
      
        console.log(res.data);
        fetchData()
      }).catch(err => console.log(err));
    }
  })
  };

  //inactive agent function
  const agentInactive = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to deactivate this agent !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(BASE_URL_API + "users/deactivate/" + id)
          .then((res) => {
            Swal.fire("Deactivated!", "This agent has been deactivated.", "success");
            // console.log(res.data);
            fetchData();
          })
          .catch((err) => console.log(err));
      }
    });
  };

  //activate agent function
  const agentActive = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to activate this agent !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(BASE_URL_API + "users/activate/" + id)
          .then((res) => {
            Swal.fire("Activated!", "This agent has been activated.", "success");
            // console.log(res.data);
            fetchData();
          })
          .catch((err) => console.log(err));
      }
    });
  };

  //agent update function
  const handleApiUpdate = (id) => {
    if (!name || !email   || !mobile) {
      swalWithBootstrapButtons.fire({
        title: "Error",
        text: "All fields are required!",
        icon: "error",
      });
      return;
    }
    const formData = new FormData();
    formData.append('image', updateImage);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('mobile', mobile);
    formData.append('aadhar_no', aadharno);
    formData.append('aadhar_imgURL', updateAadharImg);
    formData.append('vehicle_insuranceURL', updateInsurance);
    formData.append('vehicle_registrationURL', updateRegistration);
    formData.append('cityid', cityId);
    formData.append("_id",id);
    
    console.log(formData);
   

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to update this agent !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
         

          axios
            .put(BASE_URL_API + "users/updatebyadmin", formData)
            .then((res) => {
              swalWithBootstrapButtons.fire(
                "Updated!",
                "This Agent details has been updated Successfully.",
                "success"
              );
              console.log(res.data);
              setEdit(false);

              fetchData();
              setName("");
              setEmail("");
              setMobile("");
              setImage("");
              setPassword("");
              //setCityId("");
              setAadharno("");
              setAadharimg(" ");              
              setInsurance("");
              setRegistration("");

            })
            .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not Update this agent details",
            "error"
          );
          setEdit(false);
        }
      });
  };

  const rowClasses = (row) => {
    return row.isActive ? "table-lightgreen" : ""; 
  };

  //button function
  const actionsFormatter = (dataField, row) => {
    if (row.isActive) {
      return (
        <>
          <Button
            type="button"
            className="btn btn-sm "
            onClick={() => viewBtn(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="View Details"
            style={{background:"#73be44" , color:"#fff"}}
          >
            <i className="fa-solid fa-eye "></i>
          </Button>
          <Button
            type="button"
            className="btn btn-secondary btn-sm mx-1"
            onClick={() => editBtn(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa-solid fa-edit "></i>
          </Button>
          {/* <Button
            type="button"
            className="btn btn-danger btn-sm "
            onClick={() => apiDelete(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i className="fa-solid fa-trash "></i>
          </Button> */}
           <Button
            type="button"
            className="btn btn-primary btn-sm mx-1"
            onClick={() => agentInactive(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Inactive"
          >
            <i className="fa-solid fa-toggle-on" aria-hidden="true"></i>
          </Button> 
        </>
      );
    } else {
      return (
        <>
        <Button
            type="button"
            className="btn btn-light btn-sm disabled "
            onClick={() => viewBtn(dataField)}
            
          >
            <i className="fa-solid fa-eye "></i>
          </Button>
          <Button
            type="button"
            className="btn btn-light btn-sm disabled mx-1"
            onClick={() => editBtn(dataField)}
           
          >
            <i className="fa-solid fa-edit "></i>
          </Button>
          {/* <Button
            type="button"
            className="btn btn-light btn-sm disabled"
            onClick={() => apiDelete(dataField)}
            
          >
            <i className="fa-solid fa-trash "></i>
          </Button> */}
        <Button
          type="button"
          className="btn btn-primary btn-sm mx-1"
          onClick={() => agentActive(dataField)}
          data-toggle="tooltip"
            data-placement="top"
            title="Active"
        >
          <i className="fa-solid fa-toggle-off" aria-hidden="true"></i>
        </Button> 
        </>
        
      );
    }
  };

  //List Columns

  const columns = [
    {
      dataField: 'imageURL',
      text: 'Image',
      formatter: (dataField) => {
        if (dataField) {
          return (
            <img src={IMG_URL + dataField} style={{ height: '50px', width: '50px' }} className='rounded'/>
          );
        } else {
          return (
            <img src={noLogo} style={{ height: '50px', width: '50px' }} className='rounded'/>
          );
        }
      }
    }
    ,
    {
      dataField: 'name',
      text: 'Name',
      sort: 'asc',
      filter: textFilter(),
    }, {
      dataField: 'mobile',
      text: 'Mobile',
      sort: 'asc',
      filter: textFilter(),
    },
    {
      dataField: "isActive",
      text: "Status",
      formatter: (isActive) => {
        return isActive ? "Active" : "Inactive";
      },
    },
    {
      dataField: "isApproved",
      text: "Approval Status",
      formatter: (isApproved) => {
        return isApproved ? "Approved" : "Pending";
      },
    },

    {
      dataField: "_id",
      text: "Actions",
      formatter: actionsFormatter,
    },

    
  ];

  //Pagination
  const [sizePerPage, setSizePerPage] = useState(10);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setSizePerPage(sizePerPage);
  };

  
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: sizePerPage,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onTableChange: handleTableChange,
  };
//Agent approve function
  const agentApprove = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to approve this agent !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(BASE_URL_API + "users/approveUser/" + id)
          .then((res) => {
            Swal.fire("Approved!", "This agent registration has been approved.", "success");
            // console.log(res.data);
             viewBtn(id);
             fetchData();
          })
          .catch((err) => console.log(err));
      }
    });
  };
  return (
   
      <div className="card">
        <h5 className="card-header">Agents</h5>
       
        <div className="d-flex justify-content-between"> 
      <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">
        <select onChange={(e)=> setSelectedCityId(e.target.value)} className="form-select form-select-sm " style={{ width: '150px', height: '40px', borderColor:"#73be44" }}>
        <option value="">All Cities</option>
          {city && city.cities
            ? city.cities.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.city}
                </option>
              ))
            : null}
        </select>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">
      <Button className="btn" onClick={() => setAdd(true)} style={{background :"#73be44", color:"#fff"}}>
          <i className="fa-solid fa-plus"></i>Add New Agent
        </Button>
      </div>
      </div>
          {/* Agent Table */}
          <BootstrapTable
            keyField="id"
            data={agent}
            columns={columns}
            pagination={paginationFactory(options)}
            filter={filterFactory()}
            search
            rowClasses={rowClasses}
          />

          {/* Create New Agent */}
          <Modal
            size="md"
            show={add}
            onHide={() => setAdd(false)}
            aria-labelledby="example-modal-sizes-title-md"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-md">
                Add New Agent
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>City* </Form.Label>
                </Col>
                <Col xs={12} md={8}>
                <select onChange={(e) => handleCityChange(e)} className="form-select" defaultValue="">
                  <option value="" disabled>
                    Select city
                  </option>
                  {city && city.cities
                    ? city.cities.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.city}
                        </option>
                      ))
                    : null}
                  </select>
                </Col>
              </Row>
            </Form.Group>
                <Form.Group
                  className="mb-3"
                   controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Name*</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="text"
                        placeholder="Agent Name"
                        onChange={(e) => {
                          setName(e.target.value)
                          setIsValidName(/^[A-Za-z\s]+$/.test(e.target.value))
                        }}
                        isValid={isValidName && name.length > 3}
                        isInvalid={(name.length > 0 && name.length < 3) || !isValidName}
                    autoFocus
                    required
                    onKeyPress={(e) => {
                      const isAlphabetWithSpace = /^[A-Za-z\s]+$/;
                      const isValidInput = isAlphabetWithSpace.test(e.key);

                      if (!isValidInput) {
                        e.preventDefault();
                      }
                    }}
                      />
                      <Form.Control.Feedback type="invalid">
                    Please enter a valid name.
                  </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Email*</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="email"
                        placeholder="example@xyz.com"
                        onChange={(e) => setEmail(e.target.value)}
                       
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Mobile*</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="text"
                        placeholder="Agent Mobile Number"
                        onChange={(e) => {
                          setMobile(e.target.value);
                          setIsValidMobile(e.target.value.length === 10);
                         
                        }}
                        
                         isValid={mobile.length===10}
                        // isValid={mobile.length==10}
                        //isValid={isValidMobile}
                        isInvalid={!isValidMobile}
                        
                        onKeyPress={(e) => {
                          const isNumeric = /^[0-9]+$/;
                          const isValidInput = isNumeric.test(e.key);
    
                          if (!isValidInput) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={10}
                        required
                      />
                       <Form.Control.Feedback type="invalid"> 
                        Please enter a 10-digit mobile number.
                      </Form.Control.Feedback>
                    </Col>
                  </Row> 

                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  {/* <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>VehicleType</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="text"
                        placeholder="VehicleType"
                        onChange={(e) => setVehicle(e.target.value)}
                        
                        required
                      />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Vehicle Type</Form.Label>
                      </Col>
                      <Col xs={12} md={8}>
                      <Form.Select
                        onChange={(e) => setVehicle(e.target.value)}  // Attach selected value to form data
                        required
                      >
                        <option value="">Select Vehicle Type</option>  {/* Default placeholder */}
                        <option value="Auto">Auto</option>
                        <option value="Rikshaw">Rikshaw</option>
                        <option value="Cycle">Cycle</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Aadhar No*</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="text"
                        placeholder="aadhar number"
                        onChange={(e) => {
                          setAadharno(e.target.value);
                          setIsValidAadhar(e.target.value.length === 12);
                        }}
                        //isValid={isValidAadhar}
                        isValid={aadharno.length==12}
                        isInvalid={!isValidAadhar}
                        onKeyPress={(e) => {
                          const isNumeric = /^[0-9]+$/;
                          const isValidInput = isNumeric.test(e.key);
                          if (!isValidInput) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={12}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a 12-digit Aadhar number.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Image*</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="file"
                        accept='.jpg, .jpeg, .png'
                        placeholder="Image"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Aadhar Image</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="file"
                        accept='.jpg, .jpeg, .png'
                        placeholder="Aadhar photo"
                        onChange={(e) => setAadharimg(e.target.files[0])}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Vehicle RegistrationImage</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="file"
                        accept='.jpg, .jpeg, .png'
                        placeholder="Image"
                        onChange={(e) => setRegistration(e.target.files[0])}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Vehicle InsuranceImage</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="file"
                        accept='.jpg, .jpeg, .png'
                        placeholder="Image"
                        onChange={(e) => setInsurance(e.target.files[0])}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
             
              <Button variant="primary" onClick={handleApiAdd}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* View Modal */}
          <Modal
            size="lg"
            show={view}
            onHide={() => setView(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Agent Profile
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  {AgeView.map((item, i) => (
                    <>
                      <Row>
                        <Col xs={6} md={4}>
                          <Form.Label>Image</Form.Label>
                        </Col>
                        <Col xs={12} md={8}>
                        {item.imageURL ? (
    <img src={IMG_URL + item.imageURL} style={{ height: "75px", width: "75px" }} />
  ) : (
    <img src={noLogo} style={{ height: "75px", width: "75px" }} />
 ) }
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={6} md={4}>
                          <Form.Label>Name</Form.Label>
                        </Col>
                        <Col xs={12} md={8}>
                          <h4>{item.name}</h4>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={6} md={4}>
                          <Form.Label>Mobile</Form.Label>
                        </Col>
                        <Col xs={12} md={8}>
                          <h6>{item.mobile}</h6>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={6} md={4}>
                          <Form.Label>Email</Form.Label>
                        </Col>
                        <Col xs={12} md={8}>
                          <h6>{item.email}</h6>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={6} md={4}>
                          <Form.Label>Created on</Form.Label>
                        </Col>
                        <Col xs={12} md={8}>
                          <p>{item.createdAt}</p>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={6} md={4}>
                          <Form.Label>CityName</Form.Label>
                        </Col>
                        <Col xs={12} md={8}>
                          <p>{item.cityName}</p>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={6} md={4}>
                          <Form.Label>Aadhar Number</Form.Label>
                        </Col>
                        <Col xs={12} md={8}>
                          <p>{item.aadhar_no}</p>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={6} md={4}>
                          <Form.Label>Vehicle Type</Form.Label>
                        </Col>
                        <Col xs={12} md={8}>
                          <p>{item.vehicle_type}</p>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={6} md={4}>
                          <Form.Label>Related Documents</Form.Label>
                        </Col>
                        <Col xs={12} md={8}>
                        <Row>
                        <Col xs={12} md={4}>
                        <p>
                          <strong>Aadhar Image:</strong><br />
                          <img src={IMG_URL+item.aadhar_imgURL} alt="Aadhar" width="100" />
                        </p>
                        </Col>
                        <Col xs={12} md={4}>
                        <p>
                          <strong>Vehicle Registration:</strong><br />
                          <img src={IMG_URL+item.vehicle_registrationURL} alt="Vehicle Registration" width="100" />
                        </p>
                        </Col>
                        <Col xs={12} md={4}>
                        <p>
                          <strong>Vehicle Insurance:</strong><br />
                          <img src={IMG_URL+item.vehicle_insuranceURL} alt="Vehicle Insurance" width="100" />
                        </p>
                        </Col>
                        </Row>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={6} md={4}>
                          <Form.Label>Approve Status</Form.Label>
                        </Col>
                        <Col xs={12} md={8}>
                          <p>{item.isApproved ? 'Approved' : 'Pending'}</p>
                        </Col>
                      </Row>
                    </>
                  ))}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
              <div>
                {AgeView.map((item, i) => (
                !item.isApproved && (
                  <Button key={i} variant="primary" onClick={() => agentApprove(item._id)}>
                    Approve
                  </Button>
                )
                ))}
              </div>
              <div>
                <Button variant="danger" onClick={() => setView(false)}>
                  Close
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* Edit Modal */}
          <Modal
            size="lg"
            show={edit}
            onHide={() => setEdit(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Update Agent
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>City </Form.Label>
                </Col>
                <Col xs={12} md={8}>
                <select
                        onChange={(e) => setCityId(e.target.value)}
                        value={cityId}
                        className="form-select"
                      >
                        <option value="" disabled>
    Select A City
  </option>
  {city && city.cities
            ? city.cities.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.city}
                </option>
              ))
            : null}
                        </select>
                </Col>
              </Row>
            </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Name</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="text"
                        placeholder="Agents Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoFocus
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Aadhar number</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="text"
                        // placeholder="Agents Email"
                        value={aadharno || ""}
                        onChange={(e) => setAadharno(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Mobile</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="text"
                        placeholder="Agents Mobile"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Email</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Control
                        type="text"
                        placeholder="Agents Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Image</Form.Label>
                    </Col>
                    <Col xs={12} md={2}>
                      <img
                        src={IMG_URL +image} style={{ height: "75px", width: "75px" }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Control
                        type="file"
                        placeholder="Image"
                        
                        onChange={(e) => setUpdateImage(e.target.files[0])
                          
                        }
                        // value={updateImage}
                      
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Insurance image</Form.Label>
                    </Col>
                    <Col xs={12} md={2}>
                      <img
                        src={IMG_URL + insurance} style={{ height: "75px", width: "75px" }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Control
                        type="file"
                        placeholder="Image"
                        
                        onChange={(e) => setUpdateInsurance(e.target.files[0])}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Registration image</Form.Label>
                    </Col>
                    <Col xs={12} md={2}>
                      <img
                        src={IMG_URL + registration} style={{ height: "75px", width: "75px" }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Control
                        type="file"
                        placeholder="Image"
                        onChange={(e) => setUpdateRegistration(e.target.files[0])}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Aadhar Image</Form.Label>
                    </Col>
                    <Col xs={12} md={2}>
                      <img
                        src={IMG_URL + aadharimg} style={{ height: "75px", width: "75px" }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Control
                        type="file"
                        placeholder="Image"
                        onChange={(e) => setUpdateAadharImg(e.target.files[0])}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => handleApiUpdate(upId)}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        
      </div>
    
  );
}
