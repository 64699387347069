import React, {useEffect, useState} from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { BASE_URL_API, IMG_URL } from '../environment';
import axios from 'axios';
import {Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
function EventDetails() {
  const [event, setEvent] = useState([]);
  const [approveevent, setApproveEvent] = useState([]);
  const params =useParams();
  const [queryParams] = useSearchParams();
  const [title, setTitle] =useState("");
  const [description, setDescription] =useState("");
  const [date, setDate] =useState("");
  const [location, setLocation] =useState("");
  const [imageURL, setImageURL] =useState("");
  const [plgcontact, setPlgcontact] =useState("");
  const [plgmail, setPlgMail] =useState("");
  const [plgid, setPlgId] =useState("");
  const [plgname, setPlgName] =useState("");
  const [status, setStatus] =useState("");
  const [eventid, setEventid] =useState("");
  const [starttime, setStartTime] =useState("");
  const [endtime, setEndTime] =useState("");
  const [rejectReason, setRejectReason] =useState("");
  const [showModal, setShowmodal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const navigate = useNavigate();
  useEffect(() => {
    console.log(queryParams.get("type"));
    fetchData();
  }, [params.id]);
  //getting  details of event when it is creating from website. and also from rp database
  const fetchData=()=>{
    if(queryParams.get("type") === "all"){
    axios
    .get(`https://mockups.ajatus.co.in/plogging-event/wp-json/events/v1/by-id/?id=` + params.id)
    .then((res) => {
      setEvent(res.data);  
      setTitle(res.data.event_title);
      setDescription(res.data.event_shortdesc);
      setDate(res.data.event_date);
      setLocation(res.data.event_location);
      setImageURL(res.data.event_image);
      setPlgcontact(res.data.organization_phone);
      setPlgMail(res.data.organization_email);
      setPlgId(res.data.author_id);
      setPlgName(res.data.organization_name);
      setStatus(res.data.status); 
      setEventid(res.data.id); 
      setStartTime(res.data.start_time);
      setEndTime(res.data.end_time);  
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
    }else{
      axios
      .get(BASE_URL_API+"plogging/"+ params.id)
      .then((res) => {
        console.log(res.data);
        setApproveEvent(res.data["Event Details"][0]);  
      })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

    //getting all customers those registering for an event
    axios
      .get(BASE_URL_API+"plogging/"+ params.id+"/customers")
      .then((res) => {
        console.log(res.data.registeredCustomers);
        setCustomers(res.data.registeredCustomers);  
      })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
    }
    
  }

  //approve function for event
  const handleApprove = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to approve this Event !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("title", title);
          formData.append("about", description);
          formData.append("date", date);
          formData.append("eventid", eventid);
          formData.append("location", location);
          formData.append("plg_contact", plgcontact);
          formData.append("plg_mail", plgmail);
          formData.append("plg_name", plgname);
          formData.append("plg_id", plgid);
          formData.append("startTime", starttime);
          formData.append("endTime", endtime);
          formData.append("status", status);
          formData.append("imageURL", imageURL);
          
            axios
              .post(BASE_URL_API + "plogging/addPlogging", formData)
              .then((res) => {
                if (res.data.uploaded === false) {
                  swalWithBootstrapButtons.fire("Failed to accept !", "error");
                } else {
                  swalWithBootstrapButtons.fire("Approved !", "success");
                  axios
                    .post(
                      `https://mockups.ajatus.co.in/plogging-event/wp-json/events/v1/update/${eventid}`,
                      { permission: "Approved" }
                    )
                    .then((res) => {
                      console.log(res.data);
                    })
                    .catch((err) => console.log(err));
                  navigate(-1);
                }
              })
              .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not approve this product",
            "error"
          );
        }
      });
  };
  

  //reject function for event
  const handleReject = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to Reject this Event !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
               .post(`https://mockups.ajatus.co.in/plogging-event/wp-json/events/v1/update/${eventid}`,{
              permission:"Disapproved",
              disapprove_reason:rejectReason
            })
            .then((res) => { 
              swalWithBootstrapButtons.fire(
              "Rejected",
              "success"
            );
             console.log(res.data);
             setShowmodal(false);
            })
            .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "error",
          );
        }
      });
  }
  return (
    <div>
      {/* showing event details */}
      <section className="section about-section gray-bg" id="about">
        {queryParams.get("type") === "all" ? (
          <div className="container">
          <div className="row align-items-center flex-row-reverse mb-5">
            <div className="col-lg-6">
              <div className="about-text go-to">
                <h4 style={{color:"#73be44"}}>Event Details</h4>
                <h6 style={{color:"#73be44"}}>
                  Title : <span className='text-dark'>{event.event_title}</span>
                </h6>
                <div className='mb-3'>
                  <b style={{color:"#73be44"}}>Details : </b><span className='text-dark text-justify'>{event.event_shortdesc}</span> 
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Org Name :</div>
                      <div className='col-md-7'>{event.organization_name}</div>
                    </div>
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Contact No :</div>
                      <div className='col-md-7'>{event.organization_phone}</div>
                    </div>
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Email :</div>
                      <div className='col-md-7'>{event.organization_email}</div>
                    </div>
                    <div className="row">
                      <div className='col-md-5' style={{color:"#73be44"}}>Address :</div>
                      <div className='col-md-7'>{event.event_location}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Status :</div>
                      <div className='col-md-7'>{event.status}</div>
                    </div>
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Date :</div>
                      <div className='col-md-7'>{event.event_date}</div>
                    </div>
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Start Time :</div>
                      <div className='col-md-7'>{event.start_time}</div>
                    </div>
                    <div className="row">
                      <div className='col-md-5' style={{color:"#73be44"}}>End Time :</div>
                      <div className='col-md-7'>{event.end_time}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-avatar">
                <img
                  src={event.event_image}
                  title
                  alt
                />
              </div>
            </div>
          </div>
          {event?.permission === "Pending" && (
          <div className='d-flex justify-content-between'>
            <div>
              <Button type="button" className="btn  mx-1" style={{background:"red", color:"#fff"}}  onClick={()=>{setShowmodal(true)}}>
                Reject <i className="fa fa-arrow-right text-light" aria-hidden="true"></i>
              </Button>
            </div>
            <div>
              <Button type="button" className="btn mx-1" style={{background:"#73be44", color:"#fff"}} onClick={handleApprove}>
                <i className="fa fa-arrow-left text-light" aria-hidden="true"></i> Approve
              </Button>
            </div>
          </div>
          )}
          {event?.permission === "Disapproved" && (
            <div className="col-12">
              <div className="print text-center">
                <b style={{color:"red"}}>
                  This Event has been Rejected by the Admin
                </b>
              </div>
            </div>
          )}

          {event?.permission === "Approved" && (
            <div className="col-12 mb-3">
              <div className="print text-center">
                <b style={{color:"#73be44"}}>
                This Event has been Approved by the Admin
                </b>
              </div>
            </div>
          )}
        </div>
        ) : (
          <div className="container">
          <div className="row align-items-center flex-row-reverse mb-5">
            <div className="col-lg-6">
              <div className="about-text go-to">
                <h4 style={{color:"#73be44"}}>Event Details</h4>
                <h6 style={{color:"#73be44"}}>
                  Title : <span className='text-dark'>{approveevent.title}</span>
                </h6>
                <div className='mb-3'>
                  <b style={{color:"#73be44"}}>Details : </b><span className='text-dark text-justify'>{approveevent.about}</span> 
                </div>
                <div className="row mb-2 font-weight-bold">
                  <div className="col-md-6">
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Org Name :</div>
                      <div className='col-md-7'>{approveevent.plg_name}</div>
                    </div>
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Contact No :</div>
                      <div className='col-md-7'>{approveevent.plg_contact}</div>
                    </div>
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Email :</div>
                      <div className='col-md-7'>{approveevent.plg_mail}</div>
                    </div>
                    <div className="row">
                      <div className='col-md-5' style={{color:"#73be44"}}>Address :</div>
                      <div className='col-md-7'>{approveevent.location}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Status :</div>
                      <div className='col-md-7'>{approveevent.status}</div>
                    </div>
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Date :</div>
                      <div className='col-md-7'>{approveevent.date}</div>
                    </div>
                    <div className="row mb-1">
                      <div className='col-md-5' style={{color:"#73be44"}}>Start Time :</div>
                      <div className='col-md-7'>{approveevent.startTime}</div>
                    </div>
                    <div className="row">
                      <div className='col-md-5' style={{color:"#73be44"}}>End Time :</div>
                      <div className='col-md-7'>{approveevent.endTime}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-avatar">
                <img
                  src={approveevent.imageURL}
                  title
                  alt
                />
              </div>
            </div>
          </div>
          
            <div className="row align-items-center">
            <div className="col-md-6">
              <div className="mb-2">
                <h5 className="card-title">Registered Customer List </h5>
              </div>
            </div>
          </div>
          <div className="row mb-2 ">
            <div className="col-lg-12">
              <div>
              <div className="table-responsive" style={{ maxHeight: "40vh", overflowY: "auto" }}>
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                      <tr style={{backgroundColor:"#73be44", color: "#fff"}}>
                        <th scope="col">Name</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                    {customers.length > 0 ? (
                      customers.map(cus => (
                        <tr key={cus._id}>
                          <td>{cus.name}</td>
                          <td>{cus.mobile}</td>
                          <td>{cus.email}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No customers found</td>
                      </tr>
                    )}
                  </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          </div>
        
        )}
        
      </section>
      {/* model for reject  */}
      <Modal
                  size="md"
                  show={showModal}
                  onHide={()=>{setShowmodal(false)}}
                  aria-labelledby="example-modal-sizes-title-md"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      Reason
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <div className="mb-3">
                    <label className="form-label">Write the reason:</label>
                    <textarea
                      className="form-control"
                      id="example-modal-sizes-title-lg"
                      rows="4"
                      onChange={(e) => setRejectReason(e.target.value)}
                    />
                  </div>
                  </Modal.Body>


                  <Modal.Footer>
                    <Button variant="success" onClick={handleReject}>
                      Reject
                    </Button>
                  </Modal.Footer>
                </Modal>
    </div>
  );
}

export default EventDetails
