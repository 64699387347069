import React, { useState, useEffect } from 'react';
import axios from "axios";
import { BASE_URL_API } from "../environment";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Modal, Button, Form, Col, Row, Spinner } from 'react-bootstrap';
import filterFactory, { dateFilter, Comparator, textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
function UpiUpdate() {
  const [upiData, setUpiData] = useState([]);
  const [inddata, setIndData] = useState([]);
  const [view, setView] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [accountName, setAccountName] = useState("");
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  //getting all upi updates request
  const fetchData = () => {
    axios
      .get(BASE_URL_API + "payment/getReq")
      .then((res) => {
        // console.log(res.data.data);
        setUpiData(res.data.data)
    })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchData();
  }, []);

  //show upi details by id
  const handleshowadetails = (id) => () => {
    setView(true);
    axios
      .get(BASE_URL_API + "payment/getReqId/"+id)
      .then((res) => {
        console.log(res.data);
        setIndData(res.data.data)
    })
      .catch((err) => console.log(err));

  }
  //column for showing upi details in datatable
  const columns = [
    {
      dataField: 'slno',
      text: 'Sl No',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "50px" },
    },
    {
      dataField: 'customers',
      text: 'Customer Name',
      formatter: (cell) => cell,
      filter: textFilter(),
       headerStyle: { width: "180px" },
      
    },
    {
      dataField: 'email',
      text: 'Customer Email',
      formatter: (cell) => cell,
      filter: textFilter(),
    },
    {
      dataField: 'mobile',
      text: 'Customer Contact',
      formatter: (cell) => cell,
      filter: textFilter(),
    },
    {
      dataField: 'vpa',
      text: 'Change UPI',
      formatter: (cell) => cell,
      filter: textFilter(),
    },
    {
      dataField: '_id',
      text: 'Actions',
      headerStyle: { width: "90px" },
      formatter: (dataField) => (
        <Button type="button" className="btn btn-info mx-1" onClick={handleshowadetails(dataField)}>
          <i className="fa fa-eye" aria-hidden="true"></i>
        </Button>
      ),
    },
   
  ];

  const handleTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: sizePerPage,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onTableChange: handleTableChange,
  };
  const closemodal = () => {
    setView(false);
    setVerified(false);
  }
  //verify otp
  const handleVerify = (upiid) => () => {
    setLoading(true);
    axios
      .post('https://shop.ceibagreen.com/wp-json/custom-api/v1/validate/vpa', { vpa: upiid})
      .then((res) => {
        console.log(res.data);
        setAccountName(res.data.customer_name);
        setVerified(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
  //function for update upi of customer
  const handleUpdate = (id, customer_id) => () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to update this UPI ID!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .post(BASE_URL_API + "payment/aprReq", { "id": id, "customer_id": customer_id })
            .then((res) => {
              console.log(res.data);
              swalWithBootstrapButtons.fire(
                "Updated!",
                "A New UPI id set as default.",
                "success"
              );
              closemodal();
              fetchData();
            })
            .catch((err) => {
              console.log(err);
              swalWithBootstrapButtons.fire({
                title: "Error",
                text: "An Error occurred!! Try again",
                icon: "error",
              });
              closemodal();
              fetchData();
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not Update this UPI ID",
            "error"
          );
          closemodal();
          fetchData();
        }
      });
  }
  
  return (
    <div className='card'>
     <h5 className="card-header">Upi Updates</h5>
     {/* showing data in table */}
     <BootstrapTable
        keyField='slno'
        data={upiData}
        columns={columns}
        filter={filterFactory()}
        pagination={paginationFactory(options)}
        search
      />
      {/* showing details in modal */}
      <Modal
            size="md"
            show={view}
            onHide={closemodal}
            aria-labelledby="example-modal-sizes-title-md"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
              UPI update & Details
              </Modal.Title>
            </Modal.Header>
            {inddata.map((cus) =>
            <>
            <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Customer Name: </Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  {cus.customers}
                </Col>
              </Row>
            <br/>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Mobile number: </Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  {cus.mobile}
                </Col>
              </Row>
              <br/>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Email: </Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  {cus.email}
                </Col>
              </Row>

              <br/>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Payment Mode: </Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <p>{cus.mode}</p>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Update UPI: </Form.Label>
                </Col>
                <Col xs={12} md={5}>
                  <p>{cus.vpa}</p>
                  {verified ? (<p style={{fontSize:"14px",color:"#73be44"}}>Banking Name : <br/><span style={{fontWeight:"bold"}}>{accountName}</span></p>) : (<></>)}
                </Col>
                <Col xs={12} md={3}>
                {loading ? (
              <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
            ) : verified ? (
              <span style={{ color: "#73be44" }}>Verified</span>
            ) : (
                  <Button className='btn btn-sm' onClick={handleVerify(cus.vpa)}>
                    Verify
                  </Button>
                )}
              </Col>
              </Row>
              </Form.Group>
              </Form>
              </Modal.Body>
            <Modal.Footer>
            {verified ? (
              <Button className='btn btn-sm'  onClick={handleUpdate(cus._id, cus.customer_id)}>
              Update
            </Button>
             
            ) : (
                  <Button className='btn btn-sm' disabled>
                    Update
                  </Button>
                )}
            </Modal.Footer>
            </>
            )}
              
            
            
          </Modal>
    </div>
  )
}

export default UpiUpdate
