import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { BASE_URL_API, IMG_URL } from "../environment";
import axios from "axios";
import noLogo from "../img/logo01.svg";
function CustomerAllData() {
  var params = useParams();
  const navigate = useNavigate();
  const [customerAddress, setCustomerAddress] = useState([]);
  const [profile, setProfile] = useState(null);
  const [coinhist, setCoinhist] = useState(null);
  const [allupis, setAllupis] = useState([]);
  const [impss, setImpss] = useState(null);
  const [orders, setOrders] = useState([]);
  const [allord, setAllord] =useState(null);
  const [succord, setSuccord] = useState(null);
  const [pendord, setPendord] = useState(null);
  const [rejord, setRejord] = useState(null);
  const [processingord, setProcessingord] = useState(null);
  const [earnamt, setEarnamt] = useState(null);
  const [itemwise, setItemwise] = useState([]);

  useEffect(() => {
    fetchData();
  }, [params.id, params.mle]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //function for getting all data
  const fetchData = () => {
    axios
      .get(BASE_URL_API + "address/cusId/" + params.id)
      .then((res) => {
        //   console.log(res.data);
        setCustomerAddress(res.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(BASE_URL_API + "customers/" + params.mle)
      .then((res) => {
        //   console.log(res.data[0]);
        setProfile(res.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(BASE_URL_API + "orders/activecoins/" + params.id)
      .then((res) => {
        if (res.data.isSuccess && res.data.data.length > 0) {
          setCoinhist(res.data.data[0]);
        } else {
          setCoinhist(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(BASE_URL_API + "payment/getPay/" + params.id + "?mode=UPI")
      .then((res) => {
        if (res.data.isSuccess && res.data.multidata.length > 0) {
          setAllupis(res.data.multidata);
        } else {
          setAllupis([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(BASE_URL_API + "payment/getPay/" + params.id + "?mode=IMPS")
      .then((res) => {
        if (res.data.isSuccess && res.data.multidata.length > 0) {
          setImpss(res.data.multidata[0]);
        } else {
          setImpss(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(BASE_URL_API + "orders/historder/" + params.id)
      .then((res) => {
        //   console.log(res.data.data);
        setOrders(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    
    axios
      .get(BASE_URL_API + "orders/getCountoforder/" + params.id)
      .then((res) => {
        setSuccord(res.data.successfulOrders);
        setAllord(res.data.totalOrders);
        setPendord(res.data.pendingOrders);
        setProcessingord(res.data.processingOrders);
        setRejord(res.data.rejectedOrders);
        setEarnamt(res.data.paymentReceived);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(BASE_URL_API + "orders/bscustomerIndItems/" + params.id)
      .then((res) => {
        setItemwise(res.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <div className="container-fluid">
      {/* Account page navigation*/}
      <div className="row">
        <div className="col-xl-4">
          {/* Profile picture card*/}
          <div className="card mb-2">
            <div className="card-header"><b>Profile Details</b></div>
            <div className="card-body text-center" style={{ fontSize: "14px" }}>
              {/* Profile picture image*/}
              {profile ? (
                <>
                  <img
                    className="img-account-profile rounded-circle mb-2"
                    style={{ height: "100px", width: "100px" }}
                    src={profile.imageURL ? IMG_URL + profile.imageURL : noLogo}
                    alt="customer image"
                  />
                  <h5>{profile.name}</h5>
                  <p className="mb-0">
                    <strong>Mobile No :</strong> {profile.mobile}
                  </p>
                  <p className="mb-0">
                    <strong>Email :</strong> {profile.email}
                  </p>
                  <p className="mb-0">
                    <strong>PAN No :</strong> {profile.PAN ? profile.PAN : "NA"}
                  </p>
                  {profile.business_reg !== undefined && (
                    <p className="mb-0">
                      <strong>Register as Business :</strong> {profile.business_reg ? "Yes" : "No"}
                    </p>
                  )}
                  {profile.business_name && (
                    <p className="mb-0">
                      <strong>Business Name :</strong> {profile.business_name}
                    </p>
                  )}
                  {profile.gst && (
                    <p className="mb-0">
                      <strong>GST :</strong> {profile.gst}
                    </p>
                  )}
                  
                  {profile.username && (
                    <p className="mb-0">
                      <strong>Username :</strong> {profile.username}
                    </p>
                  )}
                  
                  
                </>
              ) : (
                <p>Loading profile...</p>
              )}
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-header"><b>Eco Coin Details</b></div>
            <div className="card-body text-center" style={{ fontSize: "14px" }}>
              {coinhist ? (
                <>
                  <h5>Coins: {coinhist.coins % 1 !== 0 ? coinhist.coins.toFixed(2) : coinhist.coins}</h5>
                  <p className="mb-0">
                    <strong>Total Earned :</strong> {coinhist.earn % 1 !== 0 ? coinhist.earn.toFixed(2) : coinhist.earn}
                  </p>
                  <p className="mb-0">
                    <strong>Total Spent :</strong> {coinhist.spend % 1 !== 0 ? coinhist.spend.toFixed(2) : coinhist.spend}
                  </p>
                  <p className="mb-0">
                    <strong>Total Withdrawn :</strong> {coinhist.withdraw % 1 !== 0 ? coinhist.withdraw.toFixed(2) : coinhist.withdraw}
                  </p>
                  <p className="mb-0">
                    <strong>Coins Left :</strong> {coinhist.left % 1 !== 0 ? coinhist.left.toFixed(2) : coinhist.left}
                  </p>
              </>
              ) : (
                <p style={{ fontSize: "14px" }}>No coins available.</p>
              )}
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-header"><b>Order Details</b></div>
            <div className="card-body text-center" style={{ fontSize: "14px" }}>
                  <p className="mb-0">
                    <strong>Total Orders :</strong> {allord}
                  </p>
                  <p className="mb-0">
                    <strong>Total Success Orders :</strong> {succord}
                  </p>
                  <p className="mb-0">
                    <strong>Total Pending Orders :</strong> {pendord}
                  </p>
                  <p className="mb-0">
                    <strong>Total Processing Orders :</strong> {processingord}
                  </p>
                  <p className="mb-0">
                    <strong>Total Rejected Orders :</strong> {rejord}
                  </p>
                  <p className="mb-0">
                    <strong>Total Earned Money :</strong> ₹ {earnamt}
                  </p>
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-header"><b>Item Wise Details</b></div>
            <div className="card-body text-center p-1" style={{ fontSize: "14px" }}>
              {itemwise.length > 0 ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Weight</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemwise.map((item) => (
                      <tr key={item.itemId}>
                        <td>{item.itemName}</td>
                        <td>
                          {item.unit === "Pc" ? Math.round(item.totalWeight) : item.totalWeight.toFixed(3)} {item.unit}
                        </td>
                        <td>₹ {item.totalPrice.toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div style={{ fontSize: "14px" }}>No items found</div>
              )}
            </div>


          </div>
        </div>
        <div className="col-xl-8">
          {/* All orders of the customer */}
          <div className="card mb-2">
            <div className="card-header"><b>All Orders</b></div>
            <div className="card-body p-1">
              <div className="row">
              <div className="col-12">
              <div className="table-responsive" style={{ maxHeight: '300px', overflowY: 'auto' , fontSize: "14px"}}>
  <table className="table table-bordered table-hover">
    <thead className="thead-light" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
      <tr>
        <th>Order ID</th>
        <th>Schedule Date</th>
        <th>Price</th>        
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody style={{ fontSize: '14px' }}>
      {orders.length > 0 ? (
        orders.map((order) => (
          <tr key={order._id} style={{ lineHeight: '1'}}>
            <td>{order.order_id}</td>
            <td>{order.schedule_date}</td>
            <td>{order.est_price.toFixed(2)}</td>
            <td>{order.status}</td>
            <td><Link to={`/dashboard/orders/${order._id}`} className="btn btn-sm" style={{background:"#73be44", color:"#fff"}}><i className="fa-solid fa-arrow-right"/></Link></td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="5" className="text-center">No orders found</td>
        </tr>
      )}
    </tbody>
  </table>
</div>

            </div>
              </div>
            </div>
          </div>
          {/* All Address of customer*/}
          <div className="card mb-2">
            <div className="card-header"><b>Saved Addresses</b></div>
            <div className="card-body">
              <div className="row">
                {customerAddress.length === 0 ? (
                  <p>No address found</p>
                ) : (
                  customerAddress.map((address) => (
                    <div
                      key={address._id}
                      className="col-4 mb-3"
                      style={{ fontSize: "14px" }}
                    >
                      <p className="mb-0">
                        <strong>Location :</strong> {address.location}
                      </p>
                      <p className="mb-0">
                        <strong>Landmark :</strong> {address.landmark}
                      </p>
                      <p className="mb-0">
                        <strong>City :</strong> {address.city}
                      </p>
                      <p className="mb-0">
                        <strong>State :</strong> {address.state}
                      </p>
                      <p className="mb-0">
                        <strong>PIN :</strong> {address.PIN}
                      </p>
                      <p className="mb-0">
                        <strong>Coordinates :</strong> {address.latitude},{" "}
                        {address.longitude}
                      </p>
                      <p className="mb-0">
                        <strong>Default :</strong>{" "}
                        {address.isDefault ? "Yes" : "No"}
                      </p>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          {/* All saved IMPS and UPI Id of customer */}
          <div className="card mb-2">
            <div className="card-header"><b>Bank Account Details</b></div>
            <div className="card-body" style={{ fontSize: "14px" }}>
              {impss ? (
                <>
                  <p className="mb-0"><strong>Account Holder Name :</strong> {impss.bank_account.name}</p>
                  <p className="mb-0">
                    <strong>Account Number :</strong> {impss.bank_account.account_number}
                  </p>
                  <p className="mb-0"><strong>IFSC Code :</strong> {impss.bank_account.ifsc}</p>
                  <p className="mb-0"><strong>Mode :</strong> {impss.mode}</p>
                </>
              ) : (
                <p style={{ fontSize: "14px" }}>No IMPS Account Details Available.</p>
              )}
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-header"><b>UPI Details</b></div>
            <div className="card-body">
              {allupis && allupis.length > 0 ? (
                <div className="row">
                  {allupis.map((upi, index) => (
                    <div
                      key={index}
                      className="col-4"
                      style={{ fontSize: "14px" }}
                    >
                      <p className="mb-0">
                        <strong>UPI Address:</strong> {upi.vpa.address}
                      </p>
                      <p className="mb-0">
                        <strong>Mode:</strong> {upi.mode}
                      </p>
                      <p className="mb-0">
                        <strong>Account Type:</strong> {upi.account_type}
                      </p>
                      <p className="mb-0">
                        <strong>Active Status:</strong>{" "}
                        {upi.isActive ? "Yes" : "No"}
                      </p>
                      <p className="mb-0">
                        <strong>Default :</strong> {upi.default ? "Yes" : "No"}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <p style={{ fontSize: "14px" }}>No UPI Account Details Available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerAllData;
