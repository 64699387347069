import React, { useState } from 'react';
import screen from '../img/screen.png';
import logo from '../img/WebLogo.svg';
import { useNavigate, Link } from 'react-router-dom';
import { BASE_URL_API } from '../environment';
import axios from 'axios';
import Swal from 'sweetalert2';

export const ForgotPassword =() => {
    const navigate=useNavigate();
    const [step, setStep] = useState(1); // 1: Login, 2: Enter Email, 3: Verify OTP, 4: Reset Password
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

//function to send the otp to the user
    const sendOtp = async () => {
        if (!email) {
          Swal.fire({
            title: "Error",
            text: "Please enter your email.",
            icon: "error"
          });
          return;
        }
        try {
          const response = await axios.post(`${BASE_URL_API}users/sendOtpByMailToAdmin`, { email: email });
          if (response.data.message === "OTP sent to your email") {
            Swal.fire({
              title: 'OTP Sent',
              text: 'Please check your email for OTP',
              icon: 'success',
            });
            setStep(2); // Move to OTP Verification Step
          } else if (response.data.message === "This email is not registered with us") {
            Swal.fire({
              title: "Error",
              text: "Your email is not registered with us. Please contact admin.",
              icon: "error"
            });
          }
        } catch (error) {
          console.error("Error sending OTP:", error);
          Swal.fire({
            title: "Error",
            text: "Failed to send OTP. Please try again later.",
            icon: "error"
          });
        }
      };

      //function to verify the otp
      const verifyOtp = async () => {
        if (!otp) {
          Swal.fire({
            title: "Error",
            text: "Please enter the OTP.",
            icon: "error"
          });
          return;
        }
    
        try {
          const response = await axios.post(`${BASE_URL_API}users/verifyotpBS`, { email: email, otp: otp });
          if (response.data.message === "Incorrect otp verification failed") {
            Swal.fire({
              title: "Error",
              text: "Incorrect OTP. Please check again.",
              icon: "error"
            });
          } else if (response.data.message === "verification successfull") {
            Swal.fire({
              title: "Success",
              text: "OTP verified successfully. You can now reset your password.",
              icon: "success"
            });
            setStep(3); // Move to Reset Password Step
          }
        } catch (error) {
          console.error("Error verifying OTP:", error);
          Swal.fire({
            title: "Error",
            text: "Failed to verify OTP. Please try again later.",
            icon: "error"
          });
        }
      };

      //function to update the password of the user
      const updatePassword = async () => {
        if (!newPassword || !confirmPassword) {
          Swal.fire({
            title: "Error",
            text: "Please fill in both password fields.",
            icon: "error"
          });
          return;
        }
    
        if (newPassword !== confirmPassword) {
          Swal.fire({
            title: "Error",
            text: "Password and confirm password do not match.",
            icon: "error"
          });
          return;
        }
    
        try {
          const response = await axios.put(`${BASE_URL_API}users/updatePasswordByAdmin`, { email: email, password: newPassword });
          if (response.data.message === "password updated successfully") {
            Swal.fire({
              title: "Success",
              text: "Password updated successfully. Please log in with your new password.",
              icon: "success"
            }).then(() => {
              navigate('/admin') // Return to Login Step
              // Optionally, clear all fields
              setEmail('');
              setOtp('');
              setNewPassword('');
              setConfirmPassword('');
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Failed to update password. Please try again.",
              icon: "error"
            });
          }
        } catch (error) {
          console.error("Error updating password:", error);
          Swal.fire({
            title: "Error",
            text: "An error occurred while updating the password. Please try again later.",
            icon: "error"
          });
        }
      };
      //function to navigate to the login page
      const goToHome=()=>{
        navigate('/admin')
      }

      return(
        <div className="container-fluid">
      <div className="row d-flex justify-content-center align-items-center">
        <div className="row g-0" style={{ minHeight: "100vh" }}>
          <div className="col-md-6 col-lg-6 d-none d-md-block p-3" style={{ backgroundColor: "#FFFCDC", height: "100vh", overflow: "hidden" }}>
            <div className="image-container d-flex justify-content-center align-items-center" style={{ height: "100%", width: "100%", overflow: "hidden", position: "relative" }}>
              <img src={screen} alt="login form" className="img-fluid" style={{ position: "absolute", borderRadius: "30px" }} />
            </div>
          </div>

          <div className="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
            <div className="col-md-5 col-sm-8 border-1 p-4">
              {/* {step === 1 && (
                // Login Form
                <form>
                  <div className="text-center mb-2">
                    <img className="img-responsive img-circle img-thumbnail img-user" src={logo} alt="Logo" style={{ width: "180px", border: "none" }} />
                  </div>
                  <div className="text-center mb-2">
                    <p style={{ color: "#73be44", fontSize: "20px", fontWeight: "bold" }}>Welcome to Recycle Pay Business</p>
                  </div>

                  <div className="form-outline mb-3">
                    <label className="form-label" htmlFor="form2Example17" style={{ fontSize: "13px", color: "#73be44", fontWeight: "bold" }}>
                      Enter User ID
                    </label>
                    <input
                      type="text"
                      placeholder="User ID"
                      id="form2Example17"
                      className="form-control form-control-sm"
                      style={{ borderColor: "#73be44" }}
                      value={busid}
                      onChange={(e) => setBusid(e.target.value)}
                    />
                  </div>
                  <div className="form-outline mb-3">
                    <label className="form-label" htmlFor="form2Example27" style={{ fontSize: "13px", color: "#73be44", fontWeight: "bold" }}>
                      Enter Password
                    </label>
                    <input
                      type="password"
                      placeholder="Password"
                      id="form2Example27"
                      className="form-control form-control-sm"
                      style={{ borderColor: "#73be44" }}
                      value={buspass}
                      onChange={(e) => setBuspass(e.target.value)}
                    />
                  </div>
                  <Link 
                    to="#"
                    onClick={() => setStep(2)} // Move to Enter Email Step
                    style={{ color: '#00a6dc', textDecoration: 'none' }}
                  >
                    Forgot Password?
                  </Link>

                  <div className="d-grid pt-4 mb-4">
                    <button
                      className="btn btn-sm btn-block"
                      style={{ backgroundColor: "#73be44", color: "white" }}
                      type="button" onClick={login}
                    >
                      Login
                    </button>
                  </div>
                </form>
              )} */}

              {step === 1 && (
                // Enter Email & Send OTP
                <div>
                  <h5 style={{ color: "#73be44", textAlign: "center", marginBottom: "20px" }}>Reset Password</h5>
                  <div className="form-outline mb-3">
                    <label className="form-label" htmlFor="email" style={{ fontSize: "13px", color: "#73be44", fontWeight: "bold" }}>
                      Enter Your Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control form-control-sm"
                      style={{ borderColor: "#73be44" }}
                    />
                  </div>
                  <div className="d-grid pt-4 mb-4">
                    <button
                      className="btn btn-sm btn-block"
                      style={{ backgroundColor: "#73be44", color: "white" }}
                      type="button" onClick={sendOtp}
                    >
                      Send OTP
                    </button>
                  </div>
                  <div className="d-grid mb-3">
                    <button
                      className="btn btn-sm btn-block"
                      style={{ backgroundColor: "#ff0000", color: "white" }}
                      type="button" onClick={goToHome} // Back to Login
                    >
                      Back to Login
                    </button>
                  </div>
                </div>
              )}

              {step === 2 && (
                // Verify OTP
                <div>
                  <h5 style={{ color: "#73be44", textAlign: "center", marginBottom: "20px" }}>Verify OTP</h5>
                  <div className="form-outline mb-3">
                    <label className="form-label" htmlFor="otp" style={{ fontSize: "13px", color: "#73be44", fontWeight: "bold" }}>
                      Enter OTP
                    </label>
                    <input
                      type="text"
                      id="otp"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="form-control form-control-sm"
                      style={{ borderColor: "#73be44" }}
                    />
                  </div>
                  <div className="d-grid pt-4 mb-4">
                    <button
                      className="btn btn-sm btn-block"
                      style={{ backgroundColor: "#00a6dc", color: "white" }}
                      type="button" onClick={verifyOtp}
                    >
                      Verify OTP
                    </button>
                  </div>
                  <div className="d-grid mb-3">
                    <button
                      className="btn btn-sm btn-block"
                      style={{ backgroundColor: "#ff0000", color: "white" }}
                      type="button" onClick={goToHome} // Back to Login
                    >
                      Back to Login
                    </button>
                  </div>
                </div>
              )}

              {step === 3 && (
                // Reset Password
                <div>
                  <h5 style={{ color: "#73be44", textAlign: "center", marginBottom: "20px" }}>Set New Password</h5>
                  <div className="form-outline mb-3">
                    <label className="form-label" htmlFor="newPassword" style={{ fontSize: "13px", color: "#73be44", fontWeight: "bold" }}>
                      New Password
                    </label>
                    <input
                      type="password"
                      id="newPassword"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="form-control form-control-sm"
                      style={{ borderColor: "#73be44" }}
                    />
                  </div>
                  <div className="form-outline mb-3">
                    <label className="form-label" htmlFor="confirmPassword" style={{ fontSize: "13px", color: "#73be44", fontWeight: "bold" }}>
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="form-control form-control-sm"
                      style={{ borderColor: "#73be44" }}
                    />
                  </div>
                  <div className="d-grid pt-4 mb-4">
                    <button
                      className="btn btn-sm btn-block"
                      style={{ backgroundColor: "#73be44", color: "white" }}
                      type="button" onClick={updatePassword}
                    >
                      Update Password
                    </button>
                  </div>
                  <div className="d-grid mb-3">
                    <button
                      className="btn btn-sm btn-block"
                      style={{ backgroundColor: "#ff0000", color: "white" }}
                      type="button" onClick={goToHome} // Back to Login
                    >
                      Back to Login
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
      
    
}