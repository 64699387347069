
// export const BASE_URL_API = 'https://devapisbot.ceibagreen.com/api/';  //dev url

// export const BASE_URL_API = 'https://apisbot.ceibagreen.com/api/';  //live url old

export const BASE_URL_API = 'https://singada1319api.ceibagreen.com/api/';  // live url new

// export const BASE_URL_API = 'http://localhost:6001/api/';  // localhost url

//http://localhost:6001/api/
//https://apisbot.ceibagreen.com/api/
//https://devapisbot.ceibagreen.com/api/
//https://singada1319api.ceibagreen.com/api/

//export const IMG_URL = 'https://devapisbot.ceibagreen.com/';   //dev image url

//export const IMG_URL = 'https://apisbot.ceibagreen.com/'; //live image url old

export const IMG_URL = 'https://singada1319api.ceibagreen.com/';   // live image url new

//export const IMG_URL = 'https://localhost:6001/';   // localhost image url
