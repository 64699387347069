import React, { useState, useEffect } from "react";
import axios from "axios";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { BASE_URL_API } from "../environment";
import Swal from "sweetalert2";
import { Button, Modal } from 'react-bootstrap'

export function Slots() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedSlotTimes, setSelectedSlotTimes] = useState([]);
  const [clickedDate, setClickedDate] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState("65d5772df1cccd1633087da7");
  const [cityPer, setCityPer] = useState("");
  const [city, setCity] = useState("Bhubaneswar");
  const [selectAll, setSelectAll] = useState(false);
  
  //Defined the fixed hardcoded slot time 
  const timeSlots = [
    { start: "08:45 AM", end: "09:30 AM" },
    { start: "09:45 AM", end: "10:30 AM" },
    { start: "10:30 AM", end: "11:00 AM" },
    { start: "11:00 AM", end: "11:30 AM" },
    { start: "11:30 AM", end: "12:00 PM" },
    { start: "12:30 PM", end: "01:00 PM" },
    { start: "01:30 PM", end: "02:30 PM" },
    { start: "03:00 PM", end: "03:45 PM" },
    { start: "04:00 PM", end: "04:45 PM" },
    { start: "05:00 PM", end: "05:45 PM" },
  ];

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  //fetch data according to selectedid and all cities
  const fetchData = () => {
    axios
      .get(BASE_URL_API + "slot/allSlotbyCity/"+selectedCityId)
      .then((res) => {
        const datesFromDB = res.data.data || [];
        setSelectedDates(datesFromDB);
        console.log("Dates from API:", datesFromDB);
      })
      .catch((err) => console.log(err));

    axios
      .get(BASE_URL_API + "items/allCityPercent")
      .then((res) => {
        setCityPer(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, [selectedCityId]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  //function for adding slot time according to date and cityid
  const handleAddDate = () => {
    if (selectedDate) {
      const formattedDate = `${selectedDate.getDate().toString().padStart(2, "0")}-${(selectedDate.getMonth() + 1)
        .toString().padStart(2, "0")}-${selectedDate.getFullYear()}`;

      const payload = {
        month: selectedDate.toLocaleString("en-US", { month: "short" }),
        year: selectedDate.getFullYear().toString(),
        date: [
          {
            date: formattedDate,
            time: selectedSlotTimes,
          },
        ],
        city: city,
        cityid: selectedCityId
      };
      if (selectedSlotTimes.length === 0) {
        Swal.fire({
          icon: "warning",
          title: "Please select at least one time slot",
          text: "You must select at least one time slot before adding.",
        });
        return;
      }

      axios
        .post(BASE_URL_API + "slot/createSlot", payload)
        .then((res) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Slot Date added successfully",
            showConfirmButton: false,
            timer: 2000,
          });
          fetchData();
          console.log("Date added successfully:", res);
          setSelectedDate(null);
          setSelectedSlotTimes([]);
          setSelectAll(false);
        })
        .catch((error) => {
          setSelectAll(false);
          console.error("Error adding date:", error);
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please select a date",
        text: "You must select a date before adding.",
      });
    }
  };

  //function for deleting slot according to date and cityid
  const handleDeleteDate = (date) => {
    // console.log(date);
    // console.log(date.date);
    // console.log(date.time);
   

    const [day, month, year] = date.date.split('-');
    const dateObj = new Date(`${year}-${month}-${day}`);

const formattedYear = dateObj.getFullYear().toString();
const formattedMonth = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(dateObj).toString();
// console.log(formattedYear);
// console.log(formattedMonth);
// console.log(date.date);

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .put(BASE_URL_API+"slot/delSlot", {
              year: formattedYear,
              month: formattedMonth,
              date: date.date,
              cityid: selectedCityId
            })
            .then((res) => {
              
              console.log("Date deleted successfully:", res);
              fetchData();
            })
            .catch((error) => {
              console.error("Error deleting date:", error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your Slot Date Is In List .",
            "error"
          );
        }
      });
  };

  //function for selecting time slot time in sorted order during adding
  const handleTimeSlotChange = (slot, isChecked) => {
    const slotString = `${slot.start} to ${slot.end}`;
    
    if (isChecked) {
      setSelectedSlotTimes((prevSlotTimes) => {
        const updatedTimes = [...prevSlotTimes, slotString];
        return updatedTimes.sort((a, b) => {
          const aIndex = timeSlots.findIndex(
            (s) => `${s.start} to ${s.end}` === a
          );
          const bIndex = timeSlots.findIndex(
            (s) => `${s.start} to ${s.end}` === b
          );
          return aIndex - bIndex;
        });
      });
    } else {
      setSelectedSlotTimes((prevSlotTimes) =>
        prevSlotTimes.filter((time) => time !== slotString)
      );
    }
    
    setSelectAll(
      isChecked
        ? selectedSlotTimes.length + 1 === timeSlots.length
        : false
    );
  };

  const handleDateClick = (date) => {
    console.log(date);
    setClickedDate(date);
  };

 //function for disabling the date which have already slot times
  const isDateDisabled = ({ date }) => {
    return selectedDates.some((selected) => {
      const selectedDateStrings = selected.date.map((dateObj) => dateObj.date);

      return selectedDateStrings.includes(
        `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`
      );
    });
  };

  const sortedDates = selectedDates.slice().sort((a, b) => a - b);



const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() );

const handleCityChange = (e) => {
  const selectedCityId = e.target.value;
  const selectedCity = cityPer.cities.find((city) => city._id === selectedCityId);
  if (selectedCity) {
    setSelectedCityId(selectedCityId);
    setCity(selectedCity.city);
  }
};

const [bookedSlots, setBookedSlots] = useState([]);
const [updateDate, setUpdateDate] = useState("");
const [updatedSlotTimes, setUpdatedSlotTimes] = useState([]);
const [edit, setEdit] = useState(false);
const formatDate1 = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

const handleUpdateDate = (date) =>{
  console.log(date);
  setEdit(true);
  setUpdateDate(date);
  setSelectAll(false);
  axios
   .get(`${BASE_URL_API}slot/bookedSlot/${date}/${selectedCityId}`)
   .then((res) => {
     console.log("fetching selected slottimes", res.data);
    //  setBookedSlots(res.data.bookslot[0].times);
    //  setUpdatedSlotTimes(res.data.allslot[0].time);
    const bookedSlotTimes = (res.data.bookslot[0]?.times || []).map((slot) => {
      const [start, end] = slot.split(" to ");
      return { start, end };
    });
    const allSlotTimes = (res.data.allslot[0]?.time || []).map((slot) => {
      const [start, end] = slot.split(" to ");
      return { start, end };
    });

    setBookedSlots(bookedSlotTimes);
    setUpdatedSlotTimes(allSlotTimes);
    setEdit(true);
    setUpdateDate(date);
   })
   .catch((err) => console.log(err));

}

useEffect(() => {
  if (edit) {
    const allSelected = timeSlots.every(slot =>
      updatedSlotTimes.some(time => time.start === slot.start && time.end === slot.end)
    );
    setSelectAll(allSelected);
  }
}, [edit, updatedSlotTimes]);

//edit slot time chose function
const handleCheckboxChange = (start, end, isChecked) => {
  const updatedTimes = isChecked
    ? [...updatedSlotTimes, { start, end }]
    : updatedSlotTimes.filter((time) => time.start !== start || time.end !== end);

  // when selecting a slot time it will check if it is in sorted or not.
  const sortedTimes = updatedTimes.sort((a, b) => {
    const aIndex = timeSlots.findIndex(
      (slot) => slot.start === a.start && slot.end === a.end
    );
    const bIndex = timeSlots.findIndex(
      (slot) => slot.start === b.start && slot.end === b.end
    );
    return aIndex - bIndex;
  });

  setUpdatedSlotTimes(sortedTimes);

  const allSelected = timeSlots.every(slot =>
    sortedTimes.some(time => time.start === slot.start && time.end === slot.end)
  );
  setSelectAll(allSelected);
};

const handleSelectAllChange1 = (isChecked) => {
  if (isChecked) {
    setUpdatedSlotTimes(timeSlots);
  } else {
    setUpdatedSlotTimes([]);
  }
  setSelectAll(isChecked);
};

//Rendering of slot times in modal with select
const renderTimeSlots = () => {
  return (
    <div>
        <div>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={(e) => handleSelectAllChange1(e.target.checked)}
            className="mr-2"
          />
          <label>Select All</label>
        </div>
    {timeSlots.map((slot, index) => {
    const isChecked = updatedSlotTimes.some((time) => time.start === slot.start && time.end === slot.end);
    const isDisabled = bookedSlots.some((time) => time.start === slot.start && time.end === slot.end);

    return (
      <div key={index}>
        <input
          type="checkbox"
          checked={isChecked}
          // disabled={isDisabled}
          onChange={(e) => handleCheckboxChange(slot.start, slot.end, e.target.checked)}
          className="mr-2"
        />
        <label>{slot.start} to {slot.end}</label>
      </div>
    );
  })}
  </div>
);
};

//function for updating the slot
const handleUpdateSlot = (date, updatedSlotTimes) => {
  const [day, month, year] = date.split('-');
  const dateObj = new Date(`${year}-${month}-${day}`);
  const formattedYear = dateObj.getFullYear().toString();
  const formattedMonth = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(dateObj).toString();

  console.log("times are", updatedSlotTimes);
  const formattedTimes = updatedSlotTimes.map((time) => `${time.start} to ${time.end}`);

  const payload = {
    month: formattedMonth,
    year: formattedYear,
    date: [
      {
        date: formatDate(date),
        time: formattedTimes, 
      },
    ],
    city: city,
    cityid: selectedCityId,
  };

  axios
    .post(BASE_URL_API + "slot/createSlot", payload)
    .then((res) => {
      console.log("Slot updated successfully:", res);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Slot updated successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      fetchData();
      setEdit(false);
      setSelectAll(false);
    })
    .catch((error) => {
      console.error("Error updating slot:", error);
    });
};

//multiselect slot time function
const handleSelectAllChange = (isChecked) => {
  if (isChecked) {
    const allTimes = timeSlots.map((slot) => `${slot.start} to ${slot.end}`);
    setSelectedSlotTimes(allTimes);
  } else {
    setSelectedSlotTimes([]);
  }
  setSelectAll(isChecked);
};
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-lg-7 col-md-12 col-sm-12">
          <div className="d-flex justify-content-between">
          <h5>Add Slot </h5>
          <div class="d-grid gap-2 d-md-flex justify-content-lg-start p-2 ">
          <select onChange={(e) =>  handleCityChange(e)} className="form-select form-select-sm " value={selectedCityId} style={{ width: '150px', height: '40px', borderColor:"#73be44" }}>
          {cityPer && cityPer.cities
            ? cityPer.cities.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.city}
                </option>
              ))
            : null}
        </select>
          </div>
          </div>
          <div className="card bg-light">
            <div className="card-body row">
              <div className="col-lg-7 col-md-6 col-sm-12 ">
                {/* calender for selecting date */}
                <Calendar
                  onChange={handleDateChange}
                  value={selectedDate}
                  minDate={new Date()}
                  tileDisabled={isDateDisabled}
                  className="text-primary col-12"
                />
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12 ">
                <div class="card text-center">
                  <div class="card-header text-light " style={{background:"#73be44"}}>
                    Slot Time :
                  </div>
                  <div class="card-body">
                    {selectedDate && (
                      <div className="form-check font-weight-bold border rounded border-success p-1" style={{ fontSize: "11px" }}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selectAll}
                          onChange={(e) => handleSelectAllChange(e.target.checked)}
                          id="selectAll"
                        />
                        <label
                          className="form-check-label mb-1"
                          style={{ color: '#73be44' }}
                          htmlFor="selectAll"
                        >
                          Select All
                        </label>
                      </div>
                    )}
                    {selectedDate &&
                      timeSlots.map((slot, index) => (
                        <div
                          key={index}
                          className="form-check font-weight-bold border rounded border-success p-1 "
                          style={{ fontSize: "11px" }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={selectedSlotTimes.includes(
                              `${slot.start} to ${slot.end}`
                            )}
                            onChange={(e) => handleTimeSlotChange(slot, e.target.checked)}
                            id={`flexRadioDefault${index}`}
                          />
                          <label
                            className="form-check-label mb-1"
                            style={{color:'#73be44'}}
                            htmlFor={`flexRadioDefault${index}`}
                          >
                            {`${slot.start} - ${slot.end}`}
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn mt-2 float-end"
              onClick={handleAddDate}
              style={{background:"#73be44", color:"#fff"}}
            >
              Add Slot
            </button>
          </div>
        </div>

{/* showing the slot date and time */}
        <div className="col-lg-5 col-md-12 col-sm-12">
          {selectedDates.length > 0 ? (
            <div>
              <h5>Available Slot(s) </h5>
              <div className="card bg-light text-primary">
                <div className="card-body p-1">

                <div className="card-row bg-light p-1" style={{
  overflowY: "auto",
  overflowX: "hidden",
  minHeight:"80vh",
  maxHeight: "80vh",
}}>
  {sortedDates.map((slot) => {
    return (
      <div key={slot._id}>
        {slot.date.map((date) => {
          const dateParts = date.date.split('-').map(Number);
          const dateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // Assuming date is in format DD-MM-YYYY

          if (dateObj >= tomorrow) {
            return (
              <div key={date._id}
                className={`row p-2 mb-3 text-light border border-secondary rounded ${
                  clickedDate && clickedDate.getTime() === dateObj.getTime()
                    ? "active-date"
                    : ""
                }`}
                style={{background:"#73be44"}}
                onClick={() => handleDateClick(dateObj)}>
                <div className="col-9 p-2">
                  <i className="fa fa-calendar mr-2" aria-hidden="true"></i>
                  <span className="font-weight-bold">{formatDate(date.date)}</span>
                </div>
                <div className="col-3">
                <button
                    className="btn btn-success rounded-circle mr-2"
                    onClick={(e) => {
                      // e.stopPropagation();
                      handleUpdateDate(date.date);
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button> 
                  <button
                    className="btn btn-danger rounded-circle"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteDate(date);
                    }}
                  >
                    <i className="fa fa-trash" />
                  </button> 
                </div>
                {/* slot time will show when it will clicked */}
                {clickedDate && clickedDate.getTime() === dateObj.getTime() && (
                  <div className="col-12 p-3 ">
                    <div className="card text-center rounded">
                      <div className="card-header text-light" style={{background:"#73be44", color:"#fff", fontWeight:"bold"}}>
                        Slot Time(s) :
                      </div>
                      <div className="card-body">
                        {date.time.length > 0 ? (
                          date.time.map((time, index) => (
                            <div
                              key={index}
                              className="form-check font-weight-bold border rounded border-success p-1"
                              style={{ fontSize: "11px", color: "#73be44" }}
                            >
                              {time}
                            </div>
                          ))
                        ) : (
                          <div className="text-muted">No slot time available</div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }

          return null; // Don't render dates before tomorrow
        })}
      </div>
    );
  })}
</div>

                </div>
              </div>
            </div>
          ) : (
            <div>
              <h5 className="mt-2">Available Slot(s) :-</h5>
              <div className="mt-2">No slots available.</div>
            </div>
          )}
        </div>
      </div>
      {/* modal for update slot time */}
      <Modal
            size="md"
            show={edit}
            onHide={() => {setEdit(false); setSelectAll(false);}}
            aria-labelledby="example-modal-sizes-title-md"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-md">
                <span style={{color:'#73be44'}}>Update Slot</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className="mb-2" style={{color:'#73be44'}}>Date: {updateDate}</h6>
              
            <span style={{color:'#73be44', fontSize:"14px", fontWeight:"bold"}}>{renderTimeSlots()}</span>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => handleUpdateSlot(updateDate, updatedSlotTimes)}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
    </div>
  );
}
